import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { BREAKPOINTS, COLORS } from '../../ui/variables';
import Heading2 from '../../ui/Heading2';
import Heading3 from '../../ui/Heading3';
import { TeamPageQuery } from '../../../graphql-types';
import ParagraphExLarge from '../../ui/ParagraphExLarge';
import Button from '../../ui/BaseButton';

const Header = styled.header`
  max-width: 800px;
  margin: auto;
  text-align: center;
  padding: 40px 0;

  @media ${BREAKPOINTS.MOBILE} {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const Grouping = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: auto;
  padding: 0 20px;
`;

const Avatar = styled(Img)`
  max-width: 200px;
  max-height: 200px;
  border-radius: 100px;
  margin: auto;
  margin-bottom: 12px;

  @media ${BREAKPOINTS.MOBILE} {
    max-width: 120px;
    max-height: 120px;
  }
`;

const Members = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  margin: 0;
  flex-wrap: wrap;
  @media ${BREAKPOINTS.MOBILE} {
    justify-content: space-between;
  }
`;

const TeamMember = styled.li`
  text-align: center;
  width: calc(100% / 3);
  flex-shrink: 0;
  margin-bottom: 60px;

  @media ${BREAKPOINTS.MOBILE} {
    width: 48%;
  }
`;

const GroupHeading = styled(Heading3)`
  color: ${COLORS.ORANGE};
  margin-bottom: 40px;
`;

const JobCTA = styled(Button)`
  padding: 14px 24px;
  font-size: 18px;
`;

export const pageQuery = graphql`
  query TeamPage {
    allContentfulPerson(sort: { fields: createdAt, order: ASC }) {
      nodes {
        name
        urlSlug
        position {
          positionName
        }
        avatar {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
          title
        }
      }
    }
  }
`;

const TeamPage = ({ data }: { data: TeamPageQuery }) => {
  const persons = data.allContentfulPerson.nodes;
  const doctors = persons.filter(
    person => person.position.positionName === 'Doctor'
  );
  const nurses = persons.filter(
    person => person.position.positionName === 'Nurse'
  );
  const experience = persons.filter(
    person => person.position.positionName === 'Experience Team'
  );

  return (
    <Layout>
      <SEO
        title='Our Team of Veterinary Experts'
        description='At Small Door Veterinary, we believe in the power of great doctors. Meet our diligent, caring team of veterinary experts who keeps your pets healthy.'
      />
      <Header>
        <div
          css={css`
            color: ${COLORS.ORANGE};
            text-transform: uppercase;
            margin-bottom: 14px;
          `}
        >
          Meet our team
        </div>
        <Heading2>
          At Small Door, we believe in the power of great doctors. This is the
          diligent, caring team who keep your pets healthy.{' '}
        </Heading2>
      </Header>

      <Grouping>
        <GroupHeading>Our Doctors</GroupHeading>
        <Members>
          {doctors.map(doctor => (
            <TeamMember key={doctor.name}>
              <Link to={`/team/${doctor.urlSlug}`}>
                <Avatar fluid={doctor.avatar.fluid} />
                {doctor.name}
              </Link>
            </TeamMember>
          ))}
        </Members>
      </Grouping>

      <div
        css={css`
          background: ${COLORS.LIGHT_BEIGE};
          padding: 80px 0;
        `}
      >
        <div
          css={css`
            max-width: 800px;
            margin: auto;
            text-align: center;
          `}
        >
          <div
            css={css`
              color: ${COLORS.ORANGE};
              text-transform: uppercase;
              margin-bottom: 14px;
            `}
          >
            Join the Small Door family
          </div>
          <ParagraphExLarge>
            We are always looking for passionate people to join our team.
            Whether you are a veterinarian, nurse, office manager, or
            receptionist, please get in touch.
          </ParagraphExLarge>
          <JobCTA
            type='external'
            color={COLORS.ORANGE}
            href='https://boards.greenhouse.io/smalldoor'
          >
            View Open Positions
          </JobCTA>
        </div>
      </div>
    </Layout>
  );
};

export default TeamPage;
